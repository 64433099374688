.header {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 50px;
  right: 0px;
  overflow: hidden;
  /* height: 56px; */
  /* background: #673ab7; */
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); */
  /* z-index: 50; */
}

.header h1 {
  float: left;
  margin: 0;
  padding: 0 15px;
  font-size: 24px;
  line-height: 56px;
  font-weight: 400;
  color: #fff;
}

.header nav {
  float: right;
  font-size: 100%;
}

.header nav a {
  display: inline-block;
  height: 56px;
  line-height: 56px;
  padding: 0 15px;
  min-width: 50px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  text-decoration: none;
  color: #fff;
  will-change: background-color;
}

.header nav a:hover,
.header nav a:active {
  background: rgba(0, 0, 0, 0.2);
}

.header nav a.active {
  background: rgba(0, 0, 0, 0.4);
}
