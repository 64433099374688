/* html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #fafafa;
  font-family: "Helvetica Neue", arial, sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

html,
body {
  /* height: 100%;
  width: 100%; */
  padding: 0;
  margin: 0;
  background: #fafafa;
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

select,
input,
label::before,
textarea,
input:focus,
.button,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none !important;
  box-shadow: none !important;
}

.black-text {
  color: #000;
}

* {
  box-sizing: border-box;
}

#app {
  height: 100%;
}
